import axios, { AxiosError } from 'axios';
import { SupplierTypeUser } from '../../../interfaces';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ebanxLogo from '../../../components/ebanx-logo.svg';
import TooltipInfo from '../../../components/TooltipInfo';
import { ProgressSpinner } from 'primereact/progressspinner';

type PropsFinishPayment = {
  suppliers: SupplierTypeUser[];
  dataUser: any;
  currentStep: number;
  setCurrentStep: (value: number) => void;
};

export const SimpleFinishPayment = ({
  suppliers,
  dataUser,
  currentStep,
  setCurrentStep
}: PropsFinishPayment) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  let needPayment = false;

  const [isdecimal, setIsDecimal] = useState(false);
  const [payments, setPayments] = useState<number[]>([]);
  const [hasTuition, setHasTuition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const nextStep = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/registers/${localStorage.getItem(
          'register_id'
        )}/step`,
        {
          step: currentStep + 1
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
          }
        }
      )
      .then((response: any) => {
        console.log('Step =>', response.data);
        setCurrentStep(currentStep + 1);
      })
      .catch((error: AxiosError) => console.log('Error Aval =>', error));
  };

  useEffect(() => {
    if ((user.status === 'Completado' && user.purchase?.payment_method.id !== 1) || user.status === 'Pago Convenido' || user.status === "Pago Pendiente") {
      nextStep();
    }
  }, [user]);


  if (user.purchase?.total != null && user.purchase?.total === 0) {
    needPayment = false;
  } else if (user.purchase?.payment_method.description == "Pago Cuota" && user.purchase?.status === 1) {
    needPayment = true;
  } else if (user.purchase?.payment_method.description == "Pago Cuota" && user.purchase?.status === 2) {
    needPayment = false;
  } else {
    needPayment = true;
  }

  useEffect(() => {

    let totalWithoutTuition;
    
    if  (user.price.tuition && dataUser.tuition_exempted == 1 ){

      totalWithoutTuition = user.purchase.total;
      setHasTuition(true)

    } else if (user.price.tuition) {

      setHasTuition(true)
      totalWithoutTuition = user.purchase.total - user.price.tuition;

    } else {
      setHasTuition(false)
      totalWithoutTuition = user.purchase.total;
    }

    if (user.purchase.quotes != 0 && totalWithoutTuition % user.purchase.quotes != 0) {
      setIsDecimal(true)
      const numInstallments = Number(user.purchase.quotes)

      const calculatedInstallments = calculateInstallments(totalWithoutTuition, numInstallments);
      setPayments(calculatedInstallments);

    } else {
      setIsDecimal(false)
      const numInstallments = Number(user.purchase.quotes)
      const calculatedInstallments = calculateInstallments(totalWithoutTuition, numInstallments);
      setPayments(calculatedInstallments);
    }
  }, []);


  function calculateInstallments(total: number, numInstallments: number): number[] {

    const basePayment = Math.floor(total / numInstallments);
    const remainder = total % numInstallments;

    const payments: number[] = new Array(numInstallments).fill(basePayment);
    if (remainder !== 0) {
      payments[0] += remainder;
    }

    return payments;
  }

  const handleFinishWithNoPayment = () => {
    axios.post(
      `${process.env.REACT_APP_API_BACKEND}/register_form/finish`,
      {
        register_id: String(localStorage.getItem('register_id'))
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
        }
      }
    ).then((response) => {
      window.location.reload();
    }).catch((error) => {
    });
  };

  const handleEBanxTransaction = () => {
    if (user.purchase) {
      axios.post(
        `${process.env.REACT_APP_API_BACKEND}/ebanx/start_purchase`,
        {
          register_id: String(localStorage.getItem('register_id')),
          total: user.purchase.total,
          payment_method_id: user.purchase.payment_method_id
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
          }
        }
      ).then((response: any) => {
        window.location.replace(response.data.redirect_url)
      }).catch((error: AxiosError) => {
        console.log('Error: ', error);
      })
    }
  }
  const handleTransbankTransaction = () => {
    if (user.purchase) {
      axios
        .post(
          `${process.env.REACT_APP_API_BACKEND}/transbank/start_purchase`,
          {
            register_id: String(localStorage.getItem('register_id')),
            total: user.purchase.total,
            payment_method_id: user.purchase.payment_method_id
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
            }
          }
        )
        .then((response: any) => {
          window.location.replace(response.data);
        })
        .catch((error: AxiosError) => {
          console.log('Error Transbank =>', error);
          navigate('/error_transbank');
        });
    }
  };

  const handleTransactionPaypal = () => {
    if (user.purchase) {
      axios
        .post(
          `${process.env.REACT_APP_API_BACKEND}/paypal/pay`,
          {
            amount: user.purchase.total,
            currency: String(dataUser.price.currency.code),
            register_id: String(localStorage.getItem('register_id')),
            payment_method_id: user.purchase.payment_method_id
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
            }
          }
        )
        .then((response: any) => {
          // window.location.replace(response);
          // console.log('Paypal =>', response.data);
          window.location.replace(response.data);
        })
        .catch((error: AxiosError) => console.log('Error Paypal =>', error));
    }
  };

  const handleTransactionFlow = () => {
    if (user.purchase) {
      axios
        .post(
          `${process.env.REACT_APP_API_BACKEND}/flow/pay`,
          {
            register_id: String(localStorage.getItem('register_id')),
            amount: user.purchase.total,
            currency: String(dataUser.price.currency.code),
            email: String(dataUser.user.email),
            payment_method_id: user.purchase.payment_method_id
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${localStorage.getItem(
                'token_user_latam'
              )}`
            }
          }
        )
        .then((response: any) => {
          // console.log('Response Flow =>', response.data);
          window.location.replace(response.data);
        })
        .catch((error: AxiosError) => console.log('Error Flow =>', error));
    }
  };

  const handleOtherMethodPayment = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/registers/${dataUser.id}/notification-payment`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
          }
        }
      )
      .then((response: any) => {
        // console.log('Response Flow =>', response.data);
      })
      .catch((error: AxiosError) => console.log('Error Flow =>', error));
  };

  return (
    <form method={'post'} className={'mt-8 w-full'}>
      {isLoading && (
        <div className='absolute top-0 left-0 w-full h-full bg-white z-10 grid place-items-center rounded-xl'>
          <div className='flex flex-col items-center'>
            <ProgressSpinner
              style={{ width: '60px', height: '60px' }}
              strokeWidth="7"
              fill="#ffffff"
              animationDuration="1.5s" />
            <h1 className='mt-5'>Estamos procesando tu pago...</h1>
          </div>
        </div>
      )}
      <div className=' flex items-center justify-center p-4'>
        <div className='w-full max-w-2xl'>
          <div className='shadow-sm bg-white border rounded-lg max-w-2xl w-full'>
            <div className='p-6 '>
              <div>
                <h2 className='font-bold text-2xl'>
                  Resumen de pago
                </h2>
              </div>
            </div>
            <div className='px-6'>
              <div data-orientation="horizontal" role="none" className="shrink-0 bg-gray-300 h-[1px] w-full"></div>
            </div>
            <div className='px-6 py-4 flex flex-col'>
              <label className='font-normal text-l w-full mb-2'>Programa</label>
              <input
                type='text'
                className='shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md w-full'
                placeholder={dataUser.variant_application ? dataUser.career.variant_friendly_name : dataUser.career.friendly_name}
                readOnly
              />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2'>
              <div className='px-6 py-4 flex flex-col'>
                <label className='font-normal text-l w-full mb-2'>Forma de pago</label>
                <input
                  type='text'
                  className='shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md w-full'
                  placeholder={user.purchase?.payment_method?.description ?? ''}
                  readOnly
                />
              </div>
              {hasTuition &&
                <div className='px-6 py-4 flex flex-col'>
                  <label className='font-normal text-l w-full mb-2'>{'Matrícula'}</label>
                  <input
                    type='text'
                    className='shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md w-full'
                    placeholder=
                    {
                      `$ ${((user?.price?.tuition && dataUser.tuition_exempted != 1) ? user?.price?.tuition : 0).toString()} ${user.price.currency.code || ''}`
                    }
                    readOnly
                  />
                </div>
              }
              {(user.purchase?.payment_method?.description === 'Pago Cuota' && user?.price?.tuition !== 0) && (
                <div className='px-6 py-4 flex flex-col'>
                  <label className='font-normal text-l w-full mb-2'>{'Arancel'}</label>
                  <input
                    type='text'
                    className='shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md'
                    placeholder={
                      `$ ${(user.purchase?.total - (dataUser.tuition_exempted == 1 ? 0 : user?.price?.tuition))?.toString()} ${user?.price?.currency?.code || ''}`
                    }
                    readOnly
                  />
                </div>
              )}
              <div className='px-6 py-4 flex flex-col'>
                <label className="font-normal text-l w-full mb-2">
                  {user.purchase?.payment_method?.description === 'Pago Cuota' ? (
                    <>
                      Monto total del programa
                      {hasTuition && (
                        <TooltipInfo textInfo={'Monto corresponde a la suma de la matrícula más el arancel.'}></TooltipInfo>
                      )}
                    </>
                  ) : (
                    'Monto a pagar'
                  )}
                </label>
                <input
                  type='text'
                  className='shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md w-full'
                  placeholder=
                  {
                    `$ ${user?.purchase?.total?.toString()} ${user.price.currency.code || ''}`
                  }
                  readOnly
                />
              </div>
            </div>
            {user.purchase?.quotes != null && user.purchase?.quotes > 0 &&
              (
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="px-6 py-4 flex flex-col">
                      <label className="font-normal text-l w-full mb-2">Cuota</label>
                      <input
                        type="text"
                        className="shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md"
                        placeholder={`1 de ${String(user.purchase?.quotes)}`}
                        readOnly
                      />
                    </div>

                    <div className="px-6 py-4 flex flex-col">
                      <label className="font-normal text-l w-full mb-2">Monto a pagar{hasTuition &&
                        <TooltipInfo textInfo={'Valor a pagar corresponde a la suma de la primera cuota más la matrícula.'}></TooltipInfo>
                      }</label>

                      <input
                        type="text"
                        className="shadow-sm border border-gray-200 focus:outline-none text-gray-500 text-l w-full bg-gray-50 p-2 rounded-md"
                        placeholder={`$ ${hasTuition 
                          ? Number(payments[0]) + (dataUser.tuition_exempted == 1 ? 0 : user.price.tuition || 0)
                          : payments[0] 
                          } ${user.price.currency.code || ''}`}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='px-6'>
                    <p className='text-xs text-gray-600'>
                      Este pago corresponde a la primera cuota de tu plan de {String(user.purchase?.quotes)} cuotas, los siguientes pagos serán programados según el día escogido en los pasos previos.
                    </p>
                  </div>
                </div>
              )
            }
            <div className='p-6'>
              <div data-orientation="horizontal" role="none" className="shrink-0 bg-gray-300 h-[1px] w-full"></div>
            </div>
            <div className='px-6 pb-6  flex flex-col'>
              {!needPayment && (<div className='grid gap-4 grid-cols-2 mt-3 items-end'>
                <div className='flex flex-col items-end' />
                <div className='flex flex-col items-end'>
                  <button
                    className='btn'
                    type='button'
                    onClick={() => handleFinishWithNoPayment()}
                  >
                    Finalizar
                  </button>
                </div>
              </div>)}
              {needPayment && (<div className={'mt-5'}>
                <div className=''>
                  <div>
                    <h2 className='font-bold text-2xl'>
                      Procede al pago
                    </h2>
                  </div>
                </div>
                <div className={'p-5 flex justify-center '}>
                  {suppliers.map((supplier) => {
                    switch (supplier.description) {
                      case 'Transbank':
                        return (
                          <div key={supplier.id} className='relative w-[200px] h-[100px] m-4 '>
                            <div
                              className='w-full h-full bg-center bg-cover bg-no-repeat rounded-xl shadow-xl cursor-pointer'
                              style={{
                                backgroundImage: `url(${require('../../../assets/images/webpay.png')})`
                              }}
                              onClick={() => handleTransbankTransaction()}
                            ></div>
                          </div>
                        );
                      case 'EBanx':
                        return (
                          <div key={supplier.id} className='relative w-[200px] h-[100px] m-4 '>
                            <div
                              className='w-full h-full bg-center bg-cover bg-no-repeat rounded-xl shadow-xl cursor-pointer'
                              style={{
                                backgroundImage: `url(${ebanxLogo})`,
                                backgroundSize: '100% auto'
                              }}
                              onClick={() => handleEBanxTransaction()}
                            ></div>
                          </div>
                        );
                      case 'Paypal':
                        return (
                          <>
                            {(() => {
                              if (dataUser.price.currency.code === 'CLP') {
                                return <></>;
                              } else {
                                return (
                                  <>
                                    <div key={supplier.id} className='relative bg-white rounded-xl m-4 w-[200px] h-[100px]'>
                                      <div
                                        className='w-full h-full bg-center bg-contain bg-no-repeat rounded-xl shadow-xl cursor-pointer'
                                        style={{
                                          backgroundImage: `url(${require('../../../assets/images/new-paypal-logo.jpg')})`
                                        }}
                                        onClick={() => handleTransactionPaypal()}
                                      ></div>
                                    </div>
                                  </>
                                );
                              }
                            })()}
                          </>
                        );
                      case 'Flow':
                        return (
                          <>
                            <div key={supplier.id} className='relative bg-white rounded-xl m-4 w-[200px] h-[100px]'>
                              <div
                                className='w-full h-full bg-center bg-contain bg-no-repeat rounded-xl shadow-xl cursor-pointer'
                                style={{
                                  backgroundImage: `url(${require('../../../assets/images/flow.png')})`
                                }}
                                onClick={() => handleTransactionFlow()}
                              ></div>
                            </div>
                          </>
                        );
                      case 'Otro':
                        return (
                          <>
                            <div key={supplier.id} className='relative bg-white rounded-xl m-4 w-[200px] h-[100px]'>
                              <div
                                className='w-full h-full flex justify-center items-center rounded-xl shadow-xl cursor-pointer'
                                onClick={() => handleOtherMethodPayment()}
                              >
                                <p>Otro medio de pago</p>
                              </div>
                            </div>
                          </>
                        );
                      default:
                        return <></>;
                    }
                  })}
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
