import axios, { AxiosError } from "axios";
import { ReactNode, useEffect, useState } from "react";
import TooltipInfo from "../../../components/TooltipInfo";
import { ProgressSpinner } from 'primereact/progressspinner';

type PropsFormUser = {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  registerId: string;
  token: string;
  prices: any[];
  dataUser: any;
  paymentSelected: (value: string) => void;
  tuition: number;
  discount: any;
  tuitionExempted: boolean;
  variantApplication: boolean;
};

export const SelectPayment = (props: PropsFormUser) => {
  const [cardSelected1, setCardSelected1] = useState(false);
  const [cardSelected2, setCardSelected2] = useState(false);
  const [cardSelected3, setCardSelected3] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [count, setCount] = useState(0);
  const [firstDueDate, setFirstDueDate] = useState(null);
  const [activeCount, setActiveCount] = useState(false);
  const [listPaymentMethods, setListPaymentMethods] = useState([]);
  const [listDueDates, setListDueDates] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [nQuotes, setNQuotes] = useState(0);
  const [paymentMethodIdSelected, setPaymentMethodIdSelected] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [quotesSelected, setQuotesSelected] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [isdecimal, setIsDecimal] = useState(false);
  const [payments, setPayments] = useState<number[]>([]);
  const [loader, setLoader] = useState(false);

  const fetchData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/registers/${props.registerId}/payment_methods`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_user_latam")}`,
          },
        }
      )
      .then((response) => {
        setListPaymentMethods(response.data);
      });

    await axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/registers/${props.registerId}/generation_due_dates`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_user_latam")}`,
          },
        }
      )
      .then((response) => {
        setListDueDates(response.data);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count != 0 && totalMonth % count != 0) {
      setIsDecimal(true)
      const numInstallments = Number(count)
      const calculatedInstallments = calculateInstallments(totalMonth, numInstallments);
      setPayments(calculatedInstallments);

    } else {
      setIsDecimal(false)
      setPayments([]);
    }
  }, [count, totalMonth]);


  function calculateInstallments(total: number, numInstallments: number): number[] {

    const basePayment = Math.floor(total / numInstallments);
    const remainder = total % numInstallments;

    const payments: number[] = new Array(numInstallments).fill(basePayment);
    if (remainder !== 0) {
      payments[0] += remainder;
    }

    return payments;
  }

  const prevStep = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  const nextStep = () => {
    props.setCurrentStep(props.currentStep + 1);
  };

  const handleNextStep = async () => {
    setLoader(true);
    setDisabledBtn(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/purchases/store`,
        {
          register_id: props.registerId,
          payment_method_id: paymentMethodIdSelected,
          quotes: quotesSelected,
          first_due_date: firstDueDate,
          total: totalPayment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_user_latam")}`,
          },
        }
      )
      .then((response) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BACKEND
            }/registers/${localStorage.getItem("register_id")}/step`,
            {
              // step: cardSelected2
              //   ? props.currentStep + 2
              //   : props.currentStep + 1
              step: props.currentStep + 1,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem(
                  "token_user_latam"
                )}`,
              },
            }
          )
          .then((response: any) => {
            nextStep();
          })
          .catch((error: AxiosError) => console.log("Error Aval =>", error));
      })
      .catch((error: AxiosError) => console.error(error))
  };

  function getDiscountFromProps() {
    return props.discount ? props.discount.percentage : 0;
  }

  function getTuitionFromProps() {
    return props.tuitionExempted ? 0 : props.tuition;
  }

  const getReferenceValue = (element: any) => {
    return props.variantApplication
      ? element.pivot.variant_reference_value
      : element.pivot.reference_value;
  };

  const getQuotesValue = (element: any) => {
    return props.variantApplication
      ? element.pivot.variant_quotes_value
      : element.pivot.quotes_value;
  };

  const selectCard = (key: string, element: any) => {
    setCardSelected2(false);

    switch (key) {
      case "1":
        setTotalValue(
          getReferenceValue(element) -
          (getDiscountFromProps() / 100) * getReferenceValue(element) +
          getTuitionFromProps()
        );

        setTotalMonth(
          getReferenceValue(element) -
          (getDiscountFromProps() / 100) * getReferenceValue(element)
        );
        setNQuotes(element.pivot.quotes);
        setPaymentMethodIdSelected(Number(key));

        setTotalPayment(getTuitionFromProps() +
          (getReferenceValue(element) -
            (getDiscountFromProps() / 100) * getReferenceValue(element)));

        // localStorage.setItem('paymentMethod', 'Pago en cuotas');
        setActiveCount(true);
        setCardSelected1(true);
        setCardSelected2(false);
        setCardSelected3(false);
        setDiscount(getDiscountFromProps);
        break;
      case "2":
        setTotalValue(
          getReferenceValue(element) -
          (getDiscountFromProps() / 100) * getReferenceValue(element)
        );
        // localStorage.setItem('paymentMethod', 'Pago anticipado');
        setPaymentMethodIdSelected(Number(key));
        setTotalPayment(
          getTuitionFromProps() +
          (getReferenceValue(element) -
            (getDiscountFromProps() / 100) * getReferenceValue(element))
        );
        setQuotesSelected(0);
        setDiscount(getDiscountFromProps());
        setCardSelected1(false);
        setCardSelected2(true);
        setCardSelected3(false);
        setDisabledBtn(false);
        setActiveCount(false);
        break;
      case "3":
        setDiscount(getDiscountFromProps());
        localStorage.setItem("paymentMethod", "Pago en ISA");
        setPaymentMethodIdSelected(Number(key));
        setTotalPayment(getTuitionFromProps());
        setCardSelected1(false);
        setCardSelected2(false);
        setCardSelected3(true);
        setDisabledBtn(false);
        setActiveCount(false);
        setQuotesSelected(0);
        break;
    }
  };

  const formatPrice = (value: number) => {
    return new Intl.NumberFormat("es-ES", {}).format(Math.round(value));
  };

  return (
    <form method="post" className="mt-5 ">
      <div className="flex justify-center py-5">
        <label className="text-center font-bold text-[20px]">
          Forma de pago
        </label>
      </div>
      <div className="flex justify-center">
        {listPaymentMethods.map((element: any, index) => {
          switch (element.description) {
            case "Pago Cuota":
              return (
                <div
                  key={index}
                  className={`card m-4 p-3 ${cardSelected1 && "card-selected"}`}
                  onClick={() => selectCard("1", element)}
                >
                  <p className="font-bold text-sky-500 text-[18px] text-center">
                    Cuotas mensuales
                  </p>
                  <p className="text-[12px] font-light text-center py-2 text-gray-500">
                    Paga en hasta {element.pivot.quotes} cuotas mensuales con
                    diferentes medios de pago en la fecha que más te convenga
                  </p>
                  <p className="text-[14px] font-bold">
                    Matrícula ${getTuitionFromProps()}
                  </p>
                  <p className="text-sky-500 font-bold text-lg">+</p>
                  {isdecimal == true ?
                    <>
                      <p className="text-sky-500 font-bo1ld text-2xl text-center">
                        1 cuota de
                      </p>
                      <p className="text-sky-500 font-bo1ld text-2xl text-center">

                        $
                        {formatPrice(payments[0])}{" "}
                        {props.dataUser.price.currency.code}
                      </p>
                      <p className="text-sky-500 font-bold text-lg">y</p>

                      <p className="text-sky-500 font-bo1ld text-2xl text-center">
                        {payments.length - 1} cuotas de $
                        {formatPrice(payments[1])}{" "}
                        {props.dataUser.price.currency.code}
                      </p>
                      <TooltipInfo textInfo={'Importante: La primera cuota será ligeramente diferente al resto debido al redondeo necesario para ajustar los pagos totales a cantidades enteras. Este ajuste asegura que la suma de todas las cuotas sea exacta al precio total indicado.'}></TooltipInfo>
                    </>
                    :
                    <>
                      <p className="text-sky-500 font-bo1ld text-2xl text-center">
                        {count > 0 ? count : element.pivot.quotes} cuotas de $
                        {count > 0 ? formatPrice(totalMonth / count) : formatPrice(
                          getQuotesValue(element) -
                          (getQuotesValue(element) * getDiscountFromProps()) / 100
                        )}{" "}
                        {props.dataUser.price.currency.code}
                      </p>
                    </>
                  }

                  {
                    getDiscountFromProps() != 0 ?
                      <>
                        <p className="text-sm pt-2">
                          Valor Referencia:{" "}
                          <span className="line-through">
                            ${formatPrice(getReferenceValue(element))} {props.dataUser.price.currency.code}
                          </span>
                        </p>
                        <p className="font-bold">
                          Descuento: {getDiscountFromProps()}%
                        </p>
                      </>
                      : <></>
                  }
                  <p className="font-bold text-center text-sky-500 text-sm pt-2">
                    Total a pagar: $
                    {formatPrice(
                      getReferenceValue(element) -
                      (getDiscountFromProps() / 100) *
                      getReferenceValue(element) +
                      getTuitionFromProps()
                    )}{" "}
                    {element.pivot.currency}
                  </p>
                  <p className="text-[12px] font-light text-center py-2 text-gray-500">
                    <strong>Importante:</strong> Para asegurar tu matrícula deberás realizar el pago de la primera cuota <strong>hoy</strong> usando uno de los diferentes medios de pago asociados.
                  </p>
                </div>
              );
            case "Anticipado":
              return (
                <div
                  key={index}
                  className={`card m-4 p-3 ${cardSelected2 && "card-selected"}`}
                  onClick={() => selectCard("2", element)}
                >
                  <p className="font-bold text-sky-500 text-[18px] text-center">
                    Pago con tarjeta de Crédito, Débito y Prepago
                  </p>
                  <p className="text-[12px] font-light text-center py-2 text-gray-500">
                    Estas realizando tu pago de forma segura.
                  </p>
                  <p className="text-[14px] font-bold">
                    Matrícula ${formatPrice(getTuitionFromProps())}
                    {props.dataUser.price.currency.code}
                  </p>
                  <p className="text-sky-500 font-bold text-lg">+</p>
                  <p className="text-sky-500 font-bold text-2xl text-center">
                    $
                    {formatPrice(
                      getReferenceValue(element) -
                      (getDiscountFromProps() / 100) *
                      getReferenceValue(element)
                    )}{" "}
                    {props.dataUser.price.currency.code}
                  </p>
                  <p className="text-sm pt-2">
                    Valor Referencia:{" "}
                    {getDiscountFromProps() !== 0 ? (
                      <span className="line-through">
                        ${formatPrice(getReferenceValue(element))} {props.dataUser.price.currency.code}
                      </span>
                    ) : (
                      `${formatPrice(getReferenceValue(element))} ${props.dataUser.price.currency.code}`
                    )}
                  </p>
                  {getDiscountFromProps() !== 0 && (
                    <p className="font-bold">
                      Descuento: {getDiscountFromProps()}%
                    </p>
                  )}

                  <p className="font-bold text-center text-sky-500 text-sm pt-2">
                    Total a Pagar $
                    {formatPrice(
                      getTuitionFromProps() +
                      (getReferenceValue(element) -
                        (getDiscountFromProps() / 100) *
                        getReferenceValue(element))
                    )}
                  </p>
                </div>
              );
            case "ISA":
              return (
                <div
                  key={index}
                  className={`card m-4 p-3 ${cardSelected3 && "card-selected"}`}
                  onClick={() => selectCard("3", element)}
                >
                  <p className="font-bold text-sky-500 text-[18px] text-center">
                    Acuerdo ingresos compartidos (ISA)
                  </p>
                  <p className="text-[12px] font-light text-center py-2 text-gray-500">
                    Modelo de financiamiento donde solo comienzas a pagar una
                    vez consigues trabajo, sin costo inicial
                  </p>
                  <p className="text-[14px] font-bold">
                    Matrícula ${formatPrice(getTuitionFromProps())}
                  </p>
                  <p className="text-sky-500 font-bold text-lg">+</p>
                  <p className="text-sky-500 font-bold text-2xl text-center">
                    15% de tus ingresos
                  </p>
                  <p className="font-bold text-center text-sky-500 text-sm pt-2">
                    Hasta pagar $3.500.000
                  </p>
                </div>
              );
            default:
              return <></>;
          }
        })}
      </div>
      {activeCount && (
        <>
          <div className="flex flex-col items-center justify-center mt-5">
            <label className="text-center font-bold text-[15px]">
              Número de cuotas:
            </label>
            <select
              className="w-auto  border-2 rounded-lg p-2"
              onChange={(evt: any) => {
                setCount(evt.target.value);
                if (cardSelected1) {
                  setQuotesSelected(evt.target.value);
                }
                if (
                  evt.target.value !== null &&
                  evt.target.value > 0 &&
                  firstDueDate !== "" &&
                  firstDueDate !== null
                ) {
                  setDisabledBtn(false);
                } else {
                  setDisabledBtn(true);
                }
              }}
            >
              <option value={""}>--- Nº de cuotas ---</option>;
              {(() => {
                let items: ReactNode[] = [];
                for (let i = 1; i <= nQuotes; i++) {
                  items.push(
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                }
                return items;
              })()}
            </select>
          </div>
          <div className="flex flex-col items-center justify-center mt-5">
            <label className="text-center font-bold text-[15px]">
              Fecha de facturación*:
            </label>
            <select
              onChange={(evt: any) => {
                setFirstDueDate(evt.target.value);
                if (
                  count !== null &&
                  count > 0 &&
                  evt.target.value !== "" &&
                  evt.target.value !== null
                ) {
                  setDisabledBtn(false);
                } else {
                  setDisabledBtn(true);
                }
              }}
              className="w-auto border-2 rounded-lg p-2"
            >
              <option value={""}>--- Selecciona la fecha ---</option>
              {listDueDates.map((dd: string) => (
                <option value={dd}>{dd.split("-").reverse().join("/")}</option>
              ))}
            </select>
            <div className="max-w-[500px] mx-auto">
              <p className="text-[14px] font-light text-center py-2 text-gray-500">
                <strong>*</strong> Selecciona el día en que deseas realizar el pago de la <strong>segunda cuota</strong>. El cobro se efectuará en el día que elijas, y las siguientes cuotas se programarán en los meses posteriores, siempre en esa misma fecha.
              </p>
            </div>
          </div>
          {/* {count > 0 && (
            <div className="flex flex-col justify-end items-end mt-5">
              <div className="border-2 rounded-lg flex flex-col justify-end items-end ">
                <p className="py-2 px-7 rounded-lg m-1">
                  Matrícula ${formatPrice(getTuitionFromProps())}
                </p>
                {isdecimal == true ? <>
                  <p className="py-1 px-7 rounded-lg m-1">{`1 cuota de $ ${formatPrice(payments[0])} ${" "}
                  ${props.dataUser.price.currency.code}`}
                  </p>
                  <p className=" px-7 rounded-lg ">y</p>
                  <p className="py-1 px-7 rounded-lg m-1">{`${count - 1} cuota de $ ${formatPrice(payments[1])} ${" "}
                  ${props.dataUser.price.currency.code}`}</p>

                </>
                  :
                  <p className="py-2 px-7 rounded-lg m-1">{`${count} cuotas de $ ${formatPrice(
                    totalMonth / count
                  )}`}</p>
                }
                {discount != 0 &&
                  <>
                    <p className="py-2 px-7 rounded-lg m-1 font-bold text-2xl">
                      {discount}%
                    </p>
                    <label className="text-gray-500 text-sm m-1">
                      * Descuento aplicado
                    </label>
                  </>
                }

              </div>
              <p className="font-bold mt-5 text-3xl">
                Total: ${formatPrice(totalValue)}
              </p>
            </div>
          )} */}
        </>
      )}

      <div className="flex justify-end mt-5">
        <button className="btn-prev m-1" onClick={() => prevStep()}>
          Atras
        </button>
        <button
          className={`m-1 relative inline-flex justify-center items-center min-w-[140px] ${!disabledBtn
            ? "btn px-7 py-1"
            : "bg-gray-400 disabled:cursor-not-allowed disabled:hover:bg-gray-400 text-white rounded-[0.75rem] px-7 py-1"
            }`}
          disabled={disabledBtn || loader}
          type="button"
          onClick={handleNextStep}
        >
          {loader ?
            <ProgressSpinner
              style={{ width: '25px', height: '25px' }}
              strokeWidth="5"
              fill="#9CA3AF"
              animationDuration="1.5s"
            /> : "Siguiente"}
        </button>
      </div>
    </form>
  );
};
