import { useState } from "react";

export default function TooltipInfo({ textInfo }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="relative inline-block">
            <span
                className="text-sky-500 cursor-pointer flex items-center px-1" 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-4 mr-1 text-sky-500"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 15v-4m0-4h.01"
                    />
                </svg>
            </span>
            {isHovered && (
                <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-64 p-4 text-sm text-white bg-black rounded-md shadow-lg">
                    {textInfo}
                </div>
            )}
        </div>
    );
}
