import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import React from 'react';

type PropsFormUser = {
  dataUser: any;
};

export const CheckOutView = (props: PropsFormUser) => {


  const downloadContract = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/docusign/download/${localStorage.getItem('register_id')}`,
        {
          responseType: 'blob', // Set the response type to 'blob' for binary data
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token_user_latam')}`
          }
        }
      )
      .then((response: any) => {
        // Create a Blob URL and open it in a new window or initiate download
        const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        //    window.open(url, '_blank'); // Open in a new window

        //   Para descargar
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contract.pdf';
        a.click();
      })
      .catch((error: AxiosError) =>
        console.error('Error Docusign Firma =>', error)
      );
  };

  return (
    <>
      <div className='flex justify-center items-center mt-10 pt-5 bg-gray-100'>
        <div className='w-full max-w-lg bg-white shadow-lg rounded-lg overflow-hidden'>
          <div className='p-6'>
            <h2 className='text-2xl font-semibold text-gray-700'>¡Felicidades, {props.dataUser.user.name}!</h2>
            <p className='mt-2 text-sm text-gray-600'>Has finalizado tu proceso administrativo, por lo que ya eres parte
              de la Academia de Talentos
              Digitales <strong>Desafío Latam</strong></p>
          </div>
          <div className='p-6 border-t border-gray-200'>
            <div className='flex items-center gap-4'>
              <div
                className='flex items-center justify-center h-10 w-10 rounded-full bg-blue-500 text-white'>
                <span
                  className='text-lg font-bold'>{props.dataUser.user.name.charAt(0)}{props.dataUser.user.lastname.charAt(0)}</span>
              </div>
              <div>
                <h2
                  className='font-bold text-lg text-gray-800'>{props.dataUser.user.name} {props.dataUser.user.lastname}</h2>
                <p className='text-sm text-gray-500'>{props.dataUser.user.email}</p>
              </div>
            </div>
            <div className='mt-4'>
              <h3 className='font-bold text-md text-gray-800'>Programa:</h3>
              <p className='text-sm text-gray-600'>{props.dataUser.variant_application == 1 ? props.dataUser.career.variant_friendly_name : props.dataUser.career.friendly_name}</p>
            </div>
            <div className='mt-4'>
              <h3 className='font-bold text-md text-gray-800'>Detalle del pago:</h3>
              <p
                className='text-sm text-gray-600'>{props.dataUser.purchase.payment_method.description} - {props.dataUser.purchase.supplier}</p>
            </div>
            {
              props.dataUser.form_type_id != 2 &&
              <div className='mt-4'>
                <a className='text-blue-600 underline' href='#' onClick={downloadContract}>
                  Descarga tu contrato
                </a>
              </div>
            }

          </div>
          <div className='p-6 border-t border-gray-200 '>

            <p className='mt-2 text-sm text-gray-600'>Tus accesos a las plataformas de aprendizaje serán enviados a tu
              e-mail el viernes anterior al inicio de clases.</p>

          </div>
          <div className='p-6 border-t border-gray-200 text-center'>
            <p className='my-2 '>Felicidades por aceptar el desafío, ahora</p>
            <p><strong className='text-lime-600'>¡Vamos con todo!</strong></p>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center bg-gray-100'>
        <img
          alt='Background'
          className=''
          src={require('../../../assets/images/pago_finalizado.png')}
          width={700}
        />
      </div>
    </>
  );
};
